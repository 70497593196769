/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import {
	storeDelete,
	storeGet,
	storePatch,
	storePost,
} from 'common/data/remote/store-requests';
import { IProductCategory, IProductCategoryReq } from '../entities/product-categories';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';
import { ErrorResponseBase } from 'common/domain/entities/error-response';

export class CategoryApiImpl {
	async addCategory(params: IProductCategoryReq, language: string): Promise<ApiResponse<IProductCategory>> {
		let attachmentId = 0;
		try {
			const data = new FormData();
			data.append('file', params.file);
			const fileUploadResponse = await storePost({
				url: Endpoints.STORE_CATEGORIES_ATTACHEMENT,
				data,
			});
			attachmentId = fileUploadResponse?.data?.data?.id as number;
		} catch (error: any) {
			throw error;
		}
		try {
			const reqData = {
				name: params.name,
				isVisible: params.isVisible,
				subtitle: params.subtitle,
				description: params.description,
				attachmentId,
			};
			const categoryAddResp = await storePost({
				url: Endpoints.STORE_CATEGORY,
				data: { ...reqData },
				headers: {
					'Iokee-Service-Language': language,
				},
			});
			return categoryAddResp.data as ApiResponse<IProductCategory>;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getCategories(): Promise<IProductCategory[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_CATEGORY,
			});
			const data = resp.data as ApiResponse<IProductCategory[]>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getCategoryById(id: string, language: string): Promise<IProductCategory> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_CATEGORY_BY_ID.format(id),
				headers: {
					'Iokee-Service-Language': language,
				},
			});
			const data = resp.data as ApiResponse<IProductCategory>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async updateCategoryById(id: string, attachmentId: number, language: string, params: IProductCategoryReq):
		Promise<ApiResponse<IProductCategory>> {
		let mAttachmentId = attachmentId;
		if (typeof params.file === 'object') {
			if (params.file.size) {
				try {
					const data = new FormData();
					data.append('file', params.file);
					const fileUploadResponse = await storePost({
						url: Endpoints.STORE_CATEGORIES_ATTACHEMENT,
						data,
					});
					mAttachmentId = fileUploadResponse?.data?.data?.id as number;
				} catch (error: any) {
					throw error;
				}
			}
		}
		try {
			const reqData = {
				name: params.name,
				isVisible: params.isVisible,
				subtitle: params.subtitle,
				description: params.description,
				attachmentId: mAttachmentId,
			};
			const categoryAddResp = await storePatch({
				url: Endpoints.STORE_CATEGORY_BY_ID.format(id),
				headers: {
					'Iokee-Service-Language': language,
				},
				data: { ...reqData },
			});
			const data = categoryAddResp.data as ApiResponse<IProductCategory>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async deleteCategoryById(id: string): Promise<ApiResponse<string>> {
		try {
			const resp = await storeDelete({
				url: Endpoints.STORE_CATEGORY_BY_ID.format(id),
			});
			const data = resp.data as ApiResponse<string>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}
}
