/* eslint-disable react/no-unstable-nested-components */
import { BodyText } from 'common/presentation/components';
import {
	Box, Button, IconButton,
	Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useBackofficeQuestions } from '../../../providers/question.provider';
import Space from 'common/presentation/components/space/Space';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Add, Delete, Edit } from '@mui/icons-material';
import { IAnswerRes } from '../../entities/answer.model';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddAnswerRulesDialog from '../components/AddAnswerRulesDialog';
import { getRuleType } from 'utils/rule-type';
import { IAnswerRuleResponse } from '../../entities/rule.model';
import { formatDate } from 'utils/date/date.utils';
import FormTabHeader from 'features/backoffice/common/presentation/form-tab-header/FormTabHeader';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
import AnswerForm from './components/AnswerForm';
import useLanguage from 'common/presentation/providers/language.provider';

const BackofficeAddEditAnswers = () => {
	const { t } = useTranslation('translations');
	const { questionId } = useParams();
	const { activeTab, resetTabs } = useFormTabs();
	const { languages } = useLanguage();
	const {
		getQuestionById,
		questionById,
		deleteAnswerById,
		addAnswerRule,
		getAnswersByQuestionId,
		answers,
		deleteAnswerRule,
		answerRuleLoading,
		selectedAnswerId,
		setSelectedAnswerId,
		setAnswer,
	} = useBackofficeQuestions();
	const [isRulesDialogOpen, setIsRulesDialogOpen] = useState(false);
	const [clickedAnswerId, setClickedAnswerId] = useState<number | null>(null);
	const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

	useEffectCustom(() => {
		if (questionId) {
			getQuestionById(questionId);
			getAnswersByQuestionId(questionId);
			setSelectedAnswerId(null);
			setAnswer({ text: '' });
			resetTabs();
		}
	}, [questionId]);

	const handleDeleteAnswer = (mAnswer: IAnswerRes) => {
		deleteAnswerById(mAnswer.id, parseInt(questionId || '0', 10));
	};

	const handleEditAnswer = (mAnswer: IAnswerRes) => {
		resetTabs();
		setSelectedAnswerId(mAnswer.id);
	};

	const confirmDelete = (mAnswer: IAnswerRes) => (
		confirmDialog({
			message: t('confirm_delete_info'),
			header: t('alert'),
			rejectLabel: t('cancel'),
			acceptLabel: t('yes'),
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => handleDeleteAnswer(mAnswer),
		})
	);

	const onEditClick = (mAnswer: IAnswerRes) => (
		confirmDialog({
			message: t('confirm_edit_info'),
			header: t('alert'),
			rejectLabel: t('cancel'),
			acceptLabel: t('yes'),
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-success',
			accept: () => handleEditAnswer(mAnswer),
		})
	);

	const onAddRulesClick = (mAnswer: IAnswerRes) => {
		setIsRulesDialogOpen(true);
		setClickedAnswerId(mAnswer.id);
	};

	const handleAddAnswerRule = () => {
		addAnswerRule(questionId, clickedAnswerId ?? 0).then(() => setIsRulesDialogOpen(false));
	};

	const deleteRule = (id: number, answerId: number) => {
		deleteAnswerRule(questionId, answerId, id);
	};

	const handleRuleDeleteClick = (record: IAnswerRuleResponse, answerId: number) => {
		confirmDialog({
			message: t('confirm_delete_info'),
			header: t('alert'),
			rejectLabel: t('cancel'),
			acceptLabel: t('yes'),
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteRule(record.id, answerId),
		});
	};

	const expandedRules = (data: IAnswerRes) => (
		<div className="px-3">
			<DataTable value={data.rules} size="small">
				<Column field="id" header="ID" />
				<Column field="devicesHandledByProduct" header={t('num_devices_handled_by_product')} align="center" />
				<Column field="ruleType" header={t('type')} body={(record) => getRuleType(record.ruleType, t)} />
				<Column field="createDate" header={t('created_on')} body={(options) => formatDate(options.createDate, 'DD-MM-yyyy')} />
				<Column field="product.name" header={t('product')} />
				<Column
					field="product.name"
					header={t('action')}
					body={(record) => (
						<IconButton
							onClick={() => handleRuleDeleteClick(record, data.id)}
							color="error"
						>
							<Delete />
						</IconButton>
					)}
				/>
			</DataTable>
		</div>
	);

	const allowExpansion = (rowData: IAnswerRes) => rowData.rules?.length > 0;

	return (
		<Box>
			<BodyText>
				{questionById?.description}
			</BodyText>
			<ConfirmDialog />
			<FormTabHeader isEdit={!!selectedAnswerId} />
			{activeTab === 0 && (
				<AnswerForm
					language={languages.find((el) => el.isDefault) || languages[0]}
					questionId={questionId}
					answerId={selectedAnswerId}
				/>
			)}
			{activeTab === 1 && (
				<AnswerForm
					language={languages.find((el) => !el.isDefault) || languages[1]}
					questionId={questionId}
					answerId={selectedAnswerId}
				/>
			)}
			<Space height={2} />
			<Box className="card" mt={6}>
				<DataTable
					value={answers || []}
					size="normal"
					dataKey="id"
					tableStyle={{ minWidth: '50rem' }}
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={expandedRules}
					loading={answerRuleLoading}
				>
					<Column expander={allowExpansion} style={{ width: '5rem' }} />
					<Column field="id" header="ID" align="left" />
					<Column
						field="text"
						header={t('description')}
						style={{ width: '20%' }}
						align="left"
					/>
					<Column
						header={t('action')}
						style={{ width: '20%' }}
						align="center"
						body={(options) => (
							<Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
								<IconButton color="error" onClick={() => confirmDelete(options)}>
									<Delete />
								</IconButton>
								<IconButton onClick={() => onEditClick(options)}>
									<Edit />
								</IconButton>
							</Stack>
						)}
					/>
					<Column
						header={t('add_rules')}
						style={{ width: '20%' }}
						align="right"
						body={(options) => (
							<Button
								variant="contained"
								startIcon={<Add />}
								sx={{ textTransform: 'none' }}
								onClick={() => onAddRulesClick(options)}
							>
								{t('rules')}
							</Button>
						)}
					/>
				</DataTable>
			</Box>
			<AddAnswerRulesDialog
				isDialogOpen={isRulesDialogOpen}
				onClose={() => setIsRulesDialogOpen(false)}
				onSave={handleAddAnswerRule}
			/>
		</Box>
	);
};

export default BackofficeAddEditAnswers;
