import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import {
	Box,
	Card,
	FormControlLabel,
	FormGroup,
	Grid,
	Switch,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FileInput from 'features/backoffice/common/presentation/file-upload/FileInput';
import Space from 'common/presentation/components/space/Space';
import { useCategories } from '../../../providers/product-categories.provider';
import { LoadingButton } from '@mui/lab';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { getImageURL } from 'utils/image.utils';
import { placeholderImage } from 'assets/images';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
import { ILanguage } from 'common/domain/entities/languages';

interface Props {
	language: ILanguage
}

const BackofficeAddEditProductCategoriesPage: FC<Props> = (props) => {
	const { language } = props;
	const { t } = useTranslation('translations');
	const { categoryId } = useParams();
	const { setIsAddTabButtonDisabled } = useFormTabs();
	const {
		formValues,
		onChange,
		formErrors,
		submit,
		loading,
		getCategoryById,
		setFormValues,
	} = useCategories();

	useEffectCustom(() => {
		if (categoryId && language?.id) {
			setIsAddTabButtonDisabled(false);
			getCategoryById(categoryId, language.id);
		} else {
			setIsAddTabButtonDisabled(true);
			setFormValues({
				name: '',
				file: '',
				subtitle: '',
				description: '',
				isVisible: true,
			});
		}
		return () => {
			setFormValues({
				name: '',
				file: '',
				subtitle: '',
				description: '',
				isVisible: true,
			});
		};
	}, [categoryId, language]);

	const getFileName = () => {
		if (typeof formValues.file === 'string') {
			if (!formValues.file) {
				return undefined;
			}
			return formValues.file;
		}
		return formValues.file?.name;
	};

	const handleSubmit = () => {
		submit(language.id, categoryId);
	};

	const getImageSource = () => {
		if (typeof formValues.file === 'string') {
			if (!formValues.file) {
				return placeholderImage;
			}
			return formValues.file;
		}
		if (formValues.file?.size) {
			return getImageURL(formValues.file);
		}
		return placeholderImage;
	};

	return (
		<Box sx={{ mt: 2 }}>
			<Grid container mt={4} spacing={2}>
				<Grid item md={5} xs={12}>
					<Card
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
						}}
					>
						<BackofficeTextField
							name="name"
							value={formValues.name}
							error={!!formErrors.name}
							helperText={formErrors.name}
							disabled={loading}
							onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('name')}
							placeholder={t('name')}
						/>
						<Space height={2} />
						<BackofficeTextField
							name="subtitle"
							value={formValues.subtitle}
							error={!!formErrors.subtitle}
							helperText={formErrors.subtitle}
							disabled={loading}
							onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('subtitle')}
							placeholder={t('subtitle')}
						/>
						<Space height={2} />
						<BackofficeTextField
							name="description"
							value={formValues.description}
							error={!!formErrors.description}
							helperText={formErrors.description}
							multiline
							minRows={4}
							disabled={loading}
							onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('description')}
							placeholder={t('description')}
						/>
						<Space height={2} />
						<FileInput
							name="file"
							label={t('image')}
							error={!!formErrors.file}
							helperText={formErrors.file}
							placeholder={getFileName()}
							disabled={loading}
							onChange={(event) => {
								if (event.target.files) {
									if (event.target.files.length > 0) {
										onChange('file', event.target.files[0]);
									}
								}
							}}
						/>
						<Space height={2} />
						<img src={getImageSource()} alt="Category" width="100%" />
						<Space height={2} />
						<FormGroup>
							<FormControlLabel disabled={loading} control={<Switch onChange={() => onChange('isVisible', !formValues.isVisible)} checked={formValues.isVisible} />} label={t('visible')} />
						</FormGroup>
						<Space height={2} />
						<LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
							{t('save')}
						</LoadingButton>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BackofficeAddEditProductCategoriesPage;
