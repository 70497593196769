import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import useLanguage from 'common/presentation/providers/language.provider';
import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type FormTabProps = {
	id: number
	name: string
}

type FormTabContextProps = {
	activeTab: number
	setActiveTab: (index: number) => void
	tabs: FormTabProps[]
	addTab: (name: string, changeToNewTab: boolean) => void
	isAddTabButtonDisabled: boolean
	setIsAddTabButtonDisabled: (value: boolean) => void
	resetTabs: () => void
	unsavedChanges: boolean
	setUnsavedChanges: (value: boolean) => void
}

const FormTabsContext = createContext({} as FormTabContextProps);

export const FormTabProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const { languages } = useLanguage();
	const defaultLanguage = languages.find((lang) => lang.isDefault) || languages[0];
	const [activeTab, setActiveTab] = useState<number>(0);
	const [tabs, setTabs] = useState<FormTabProps[]>([]);
	const [isAddTabButtonDisabled, setIsAddTabButtonDisabled] = useState(true);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	useEffectCustom(() => {
		if (languages.length > 0) {
			setTabs([{
				id: 1,
				name: t(defaultLanguage.id),
			}]);
		}
	}, [languages]);

	const addTab = (name: string, changeToNewTab: boolean) => {
		setTabs((prev) => [...prev, {
			id: 2,
			name,
		}]);
		if (changeToNewTab) {
			setActiveTab(tabs.length);
		}
	};

	const resetTabs = () => {
		setTabs([{
			id: 1,
			name: t(defaultLanguage.id),
		}]);
		setActiveTab(0);
		setUnsavedChanges(false);
	};

	const value = useMemo(() => ({
		activeTab,
		setActiveTab,
		addTab,
		tabs,
		isAddTabButtonDisabled,
		setIsAddTabButtonDisabled,
		resetTabs,
		unsavedChanges,
		setUnsavedChanges,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [activeTab, tabs, isAddTabButtonDisabled, unsavedChanges]);

	return (
		<FormTabsContext.Provider value={value}>
			{children}
		</FormTabsContext.Provider>
	);
};

export const useFormTabs = () => useContext(FormTabsContext);
