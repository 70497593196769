/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { Endpoints } from 'assets/Endpoints';
import { ILanguage } from '../domain/entities/languages';
import { ApiResponse } from './entities/api-response';
import { storeGet } from './remote/store-requests';

export class LanguageApi {
	async getAllLanguages(): Promise<ILanguage[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.LANGUAGES,
			});
			const response = resp.data as ApiResponse<ILanguage[]>;
			return response.data;
		} catch (err) {
			throw err;
		}
	}
}
