/* eslint-disable @typescript-eslint/no-explicit-any */
import * as locales from '@mui/material/locale';
import React, {
	createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, pt } from 'date-fns/locale';
import useEffectCustom from '../hooks/useEffectCustom';
import { ILanguage } from 'common/domain/entities/languages';
import { LanguageApi } from 'common/data/language-api';
import useFeedback from './feedback.provider';

export type SupportedLocales = keyof typeof locales;

export interface LanguageContextProps {
	locale: locales.Localization;
	changeLocale: (item: SupportedLocales) => void;
	localeFns: Locale;
	languages: ILanguage[];
}

const localeMap = {
	enUS,
	ptPT: pt,
};

export const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { i18n } = useTranslation();
	const { addDialog } = useFeedback();
	const [languages, setLanguages] = useState<ILanguage[]>([]);
	const langString = i18n.language.replace('-', '') as keyof typeof localeMap;
	const savedLocaleJson: keyof typeof localeMap = langString;
	const savedLocale = locales[savedLocaleJson as SupportedLocales];
	const [locale, setLocale] = useState<locales.Localization>(savedLocale);
	const [localeFns, setLocaleFns] = useState<Locale>(localeMap[savedLocaleJson]);

	const LanguageApiImpl = new LanguageApi();

	const getAllLanguages = async () => {
		try {
			const mLanguages = await LanguageApiImpl.getAllLanguages();
			setLanguages(mLanguages);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	useEffectCustom(() => {
		getAllLanguages();
	}, []);

	const changeLocale = useCallback((lang: SupportedLocales) => {
		i18n.changeLanguage(lang.substring(0, 2));
		document.documentElement.lang = lang.substring(0, 2);
		const mLocale = locales[lang as SupportedLocales];
		setLocale(mLocale);
		setLocaleFns(localeMap[lang as keyof typeof localeMap]);

		// LocalStorageWrapper.set(LocalStorageKeys.LOCALE, lang);
		window.location.reload();
	}, [i18n]);

	const value = useMemo(() => (
		{
			locale, changeLocale, localeFns, languages,
		}
	), [locale, changeLocale, localeFns, languages]);
	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default function useLanguage() {
	return useContext(LanguageContext);
}
