import useLanguage from 'common/presentation/providers/language.provider';
import { BodyText } from 'common/presentation/components';
import FormTabHeader from 'features/backoffice/common/presentation/form-tab-header/FormTabHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import BackofficeAddEditQuestionsPage from './BackofficeAddEditQuestionsPage';

const BackofficeAddEditQuestion = () => {
	const { t } = useTranslation('translations');
	const { languages } = useLanguage();
	const { questionId } = useParams();
	const { activeTab, resetTabs } = useFormTabs();

	useEffectCustom(() => {
		if (!questionId) {
			resetTabs();
		}
	}, [questionId]);

	return (
		<main>
			<BodyText>
				{questionId
					? t('edit_question')
					: t('add_question')}
			</BodyText>
			<FormTabHeader isEdit={!!questionId} />
			{activeTab === 0 && <BackofficeAddEditQuestionsPage language={languages.find((el) => el.isDefault) || languages[0]} />}
			{activeTab === 1 && (
				<BackofficeAddEditQuestionsPage
					language={languages.find((el) => !el.isDefault) || languages[1]}
					isTranslationTab
				/>
			)}
		</main>
	);
};

export default BackofficeAddEditQuestion;
