/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { BodyText } from 'common/presentation/components';
import {
	Box,
	Card,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import Space from 'common/presentation/components/space/Space';
import { useCategories } from '../../../providers/product-categories.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import { useBackofficeProducts } from '../../../providers/product.provider';
import { Formik, Form, FormikHelpers } from 'formik';
import { useUseProductValidation } from '../hooks/useProductValidation';
import SpecImage from '../components/SpecImage';
import { Editor } from 'primereact/editor';
import { ILanguage } from 'common/domain/entities/languages';
import { EmptyProduct } from '../../entities/empty-product-values';
import { BackofficeProductRequest } from '../../entities/backoffice-product';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';

type TSetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void

interface Props {
	language: ILanguage
}

const BackofficeAddEditProductTranslation: React.FC<Props> = (props) => {
	const { language } = props;
	const { productId } = useParams();
	const { setUnsavedChanges } = useFormTabs();
	const { t } = useTranslation('translations');
	const {
		formValues,
		submit,
		uploadImage,
		getProductById,
		setFormValues,
	} = useBackofficeProducts();
	const { getCategories } = useCategories();
	const { productSchema } = useUseProductValidation();
	useEffectCustom(() => {
		getCategories();
		if (productId && language.id) {
			getProductById(productId, language.id);
		} else {
			setFormValues(EmptyProduct);
		}
		return () => {
			setFormValues(EmptyProduct);
		};
	}, [productId, language]);

	const handlAddInstallationStep = (
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mInstallationSteps = [...values.installationSteps];
		mInstallationSteps.push({
			header: '',
			description: '',
			stepOrder: values.installationSteps.length + 1,
			attachmentId: '',
		});
		setFieldValue('installationSteps', mInstallationSteps);
	};

	const handleAddSpecification = (
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications.push({
			header: '',
			description: '',
			attachmentId: '',
		});
		setFieldValue('specifications', mSpecifications);
	};

	const handleSpecificationDescriptionChange = (
		key: string,
		value: string | null,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		setFieldValue('specifications', mSpecifications);
	};

	const handleSpecificationChange = async (
		key: string,
		value: File | string | number,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		if (key === 'attachmentId' && typeof value === 'object' && value.size) {
			const imageRes = await uploadImage(value);
			if (imageRes) {
				mSpecifications[index] = {
					...mSpecifications[index],
					attachmentId: imageRes.id.toString(),
					url: imageRes.url,
				};
			}
		} else {
			mSpecifications[index] = {
				...mSpecifications[index],
				[key]: value,
			};
		}
		setFieldValue('specifications', mSpecifications);
	};

	const handleStepsChange = async (
		key: string,
		value: File | string | number,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSteps = [...values.installationSteps];
		if (key === 'attachmentId' && typeof value === 'object' && value.size) {
			const imageRes = await uploadImage(value);
			if (imageRes) {
				mSteps[index] = {
					...mSteps[index],
					attachmentId: imageRes.id.toString(),
					url: imageRes.url,
				};
			}
		} else {
			mSteps[index] = {
				...mSteps[index],
				[key]: value,
			};
		}
		setFieldValue('installationSteps', mSteps);
	};

	const handleFieldValueChange = (key: string, value: string | boolean | number | null | undefined, setFieldValue: TSetFieldValue) => {
		setFieldValue(key, value);
	};

	const handleFormSubmit = (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
	) => {
		if (productId) {
			submit(values, formikHelpers, language.id, productId);
		} else {
			submit(values, formikHelpers, language.id);
		}
	};

	return (
		<Box>
			<Card
				sx={{
					boxShadow: 'none',
					p: 4,
					gap: 2,
					mt: 4,
				}}
			>
				<Formik
					initialValues={formValues}
					validationSchema={productSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						errors,
						values,
						initialValues,
						handleChange,
						setFieldValue,
						isSubmitting,
					}) => {
						useEffect(() => {
							const isChanged = JSON.stringify(values) !== JSON.stringify(initialValues);
							setUnsavedChanges(isChanged);
						}, [values, initialValues]);
						return (
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12} md={4}>
										<BackofficeTextField
											name="name"
											error={!!errors.name}
											helperText={errors.name}
											disabled={isSubmitting}
											onChange={handleChange}
											value={values.name}
											label={t('name')}
											placeholder={t('name')}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<BackofficeTextField
											name="storageNote"
											onChange={handleChange}
											value={values.storageNote}
											error={!!errors.storageNote}
											helperText={errors.storageNote}
											disabled={isSubmitting}
											label={t('storage_note')}
											placeholder={t('storage_note')}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={2}>
									<Grid item xs={12} md={8}>
										<BackofficeTextField
											name="description"
											value={values.description}
											error={!!errors.description}
											helperText={errors.description}
											disabled={isSubmitting}
											onChange={handleChange}
											multiline
											minRows={3}
											label={t('description')}
											placeholder={t('description')}
										/>
									</Grid>
								</Grid>
								<Space height={4} />
								<Grid container spacing={2}>
									<Grid item xs={12} md={8}>
										<BodyText>
											{t('how_it_works')}
										</BodyText>
										<Space height={1} />
										<Editor
											value={values.howItWorks}
											disabled={isSubmitting}
											placeholder={t('how_it_works')}
											onTextChange={(e) => handleFieldValueChange('howItWorks', e.htmlValue, setFieldValue)}
											style={{ height: '100px', width: '100%' }}
										/>
										<Typography variant="caption" color="error">
											{
											// @ts-ignore
												errors?.howItWorks && String(errors?.howItWorks || '')
											}
										</Typography>
									</Grid>
								</Grid>
								<Space height={4} />
								<Space height={3} />
								<Stack direction="row" alignItems="center" spacing={2}>
									<BodyText>
										{t('specifications')}
									</BodyText>
									<IconButton
										disabled={isSubmitting}
										onClick={() => handleAddSpecification(values, setFieldValue)}
									>
										<Add />
									</IconButton>
								</Stack>
								{values.specifications.map((specification, index) => (
									<Box key={`specification-${index}`}>
										<Space height={2} />
										<Grid container spacing={2}>
											<Grid item xs={12} md={3}>
												<BackofficeTextField
													name="header"
													onChange={(e) => handleSpecificationChange('header', e.target.value, index, values, setFieldValue)}
													value={specification.header}
													disabled={isSubmitting}
													// error={!!errors?.specifications[index]?.header}
													// helperText={errors?.specifications[index]?.header}
													label={t('header')}
													placeholder={t('header')}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.specifications && String(errors?.specifications[index]?.header || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Editor
													value={specification.description}
													disabled={isSubmitting}
													placeholder={t('description')}
													onTextChange={(e) => handleSpecificationDescriptionChange('description', e.htmlValue, index, values, setFieldValue)}
													style={{ height: '100px' }}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.specifications && String(errors?.specifications[index]?.description || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} md={1}>
												<SpecImage
													id={`specification-${index}`}
													imageToPreview={specification.url}
													disabled
												/>
											</Grid>
										</Grid>
									</Box>
								))}
								<Space height={3} />
								<Stack direction="row" alignItems="center" spacing={2}>
									<BodyText>
										{t('installation_steps')}
									</BodyText>
									<IconButton
										disabled={isSubmitting}
										onClick={() => handlAddInstallationStep(values, setFieldValue)}
									>
										<Add />
									</IconButton>
								</Stack>
								{/* Map from array of inputs */}
								{values.installationSteps.map((step, index) => (
									<Box key={`step-${index}`}>
										<Space height={2} />
										<Grid container spacing={2}>
											<Grid item xs={12} md={3}>
												<BackofficeTextField
													name="header"
													disabled={isSubmitting}
													onChange={(e) => handleStepsChange('header', e.target.value, index, values, setFieldValue)}
													value={step.header}
													label={t('header')}
													placeholder={t('header')}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.installationSteps && String(errors?.installationSteps[index]?.header || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} md={3}>
												<BackofficeTextField
													name="description"
													disabled={isSubmitting}
													onChange={(e) => handleStepsChange('description', e.target.value, index, values, setFieldValue)}
													value={step.description}
													label={t('description')}
													placeholder={t('description')}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.installationSteps && String(errors?.installationSteps[index]?.description || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} md={3}>
												<BackofficeTextField
													type="number"
													disabled
													value={index + 1}
													name="stepOrder"
													label={t('step_order')}
													placeholder={t('step_order')}
												/>
											</Grid>
											<Grid item xs={12} md={1}>
												<SpecImage
													id={`step-${index}`}
													imageToPreview={step.url}
													disabled
												/>
											</Grid>
										</Grid>
									</Box>
								))}
								<Space height={2} />
								<LoadingButton loading={isSubmitting} type="submit" variant="contained">
									{t('save')}
								</LoadingButton>
							</Form>
						);
					}}
				</Formik>
			</Card>
		</Box>
	);
};

export default BackofficeAddEditProductTranslation;
