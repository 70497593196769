/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { IAttachmentResp } from '../../common/domain/entities/attachment';
import { FormikHelpers } from 'formik';
import { putDecimalPoints } from 'utils/price.utils';
import { AttachmentProduct, BackofficeProductRequest, ProductBackoffice } from '../products/entities/backoffice-product';
import { EmptyProduct } from '../products/entities/empty-product-values';
import { ProductBackofficeApiImpl } from '../products/data/product-api-impl';
import { useFormTabs } from '../../common/presentation/providers/tab-provider';

interface FetchState {
	loading: boolean;
	error: string;
}

type Props = {
	formValues: BackofficeProductRequest
	setFormValues: React.Dispatch<React.SetStateAction<BackofficeProductRequest>>
	submit: (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
		languageId: string,
		id?: string
	) => void
	uploadImage: (params: File) => Promise<IAttachmentResp | false>
	getProducts: () => void
	products: ProductBackoffice[]
	deleteProduct: (id: string) => void
	getProductById: (id: string, languageId: string) => void
	productsState: FetchState
}

export const BackofficeProductContext = createContext({} as Props);

export const BackofficeProductProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setIsAddTabButtonDisabled, setUnsavedChanges, setActiveTab } = useFormTabs();
	const { addDialog } = useFeedback();
	const [formValues, setFormValues] = useState<BackofficeProductRequest>(EmptyProduct);
	const [products, setProducts] = useState([] as ProductBackoffice[]);
	const [productsState, setProductsState] = useState<FetchState>({ loading: false, error: '' });

	const ProductApi = new ProductBackofficeApiImpl();

	const submit = async (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
		langaugeId: string,
		id?: string,
	) => {
		formikHelpers.setSubmitting(true);
		if (id) {
			try {
				const updateProductRes = await ProductApi.updateBackofficeProduct(values, id, langaugeId);
				addDialog({
					message: updateProductRes.message || t('success'),
					error: false,
				});
				formikHelpers.setSubmitting(false);
				setIsAddTabButtonDisabled(false);
				setUnsavedChanges(false);
				setActiveTab(0);
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
				formikHelpers.setSubmitting(false);
			} finally {
				formikHelpers.setSubmitting(false);
			}
		} else {
			try {
				const addProductRes = await ProductApi.addBackofficeProduct(values, langaugeId);
				addDialog({
					message: addProductRes.message || t('success'),
					error: false,
				});
				navigate(AppLinks.BACKOFFICE_EDIT_PRODUCT.formatMap({ productId: addProductRes.data.id }));
				setUnsavedChanges(false);
				setActiveTab(0);
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
				formikHelpers.setSubmitting(false);
			} finally {
				formikHelpers.setSubmitting(false);
			}
		}
	};

	const uploadImage = async (params: File): Promise<IAttachmentResp | false> => {
		try {
			const uploadImageRes = await ProductApi.uploadProductAttachments(params);
			return uploadImageRes;
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
		return false;
	};

	const getProducts = async () => {
		setProductsState({ loading: true, error: '' });
		try {
			const getProductsRes = await ProductApi.getBackofficeProducts();
			setProducts(getProductsRes);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setProductsState({ loading: false, error: '' });
		}
	};

	const getProductById = async (id: string, languageId: string) => {
		try {
			const getProductRes = await ProductApi.getProductById(id, languageId);
			const mAttachments = [] as AttachmentProduct[];
			const isMainAttachment = getProductRes?.attachments?.find(((el) => el.isMain));
			if (isMainAttachment) {
				mAttachments.push({
					attachmentId: isMainAttachment.attachment.id?.toString(),
					isMain: isMainAttachment.isMain,
					url: isMainAttachment.attachment.url,
					id: isMainAttachment.id,
				});
			}
			getProductRes?.attachments?.forEach((el) => {
				if (!el.isMain) {
					mAttachments.push({
						attachmentId: el.attachment.id?.toString(),
						isMain: el.isMain,
						url: el.attachment.url,
						id: el.id,
					});
				}
			});
			setFormValues({
				...formValues,
				name: getProductRes.name,
				description: getProductRes.description,
				price: putDecimalPoints(getProductRes.price),
				warrantyMonths: (getProductRes.warrantyMonths).toString(),
				storageNote: getProductRes.storageNote,
				howItWorks: getProductRes.howItWorks,
				categoryId: getProductRes?.category?.id,
				isHighlighted: getProductRes.isHighlighted,
				attachments: mAttachments,
				installationSteps: getProductRes?.installationSteps?.map((el) => ({
					id: el.id,
					header: el.header,
					description: el.description,
					stepOrder: el.stepOrder,
					attachmentId: el.attachment?.id?.toString(),
					url: el.attachment?.url,
				})),
				specifications: getProductRes?.specifications?.map((el) => ({
					id: el.id,
					header: el.header,
					description: el.description || '',
					attachmentId: el.attachment?.id?.toString(),
					url: el.attachment?.url,
				})),
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setProductsState({ loading: false, error: '' });
		}
	};

	const deleteProduct = async (id: string) => {
		try {
			const deleteProductRes = await ProductApi.deleteProductById(id);
			addDialog({
				message: deleteProductRes.message || t('success'),
				error: false,
			});
			getProducts();
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const value = useMemo(() => ({
		formValues,
		setFormValues,
		submit,
		uploadImage,
		products,
		getProducts,
		deleteProduct,
		getProductById,
		productsState,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [formValues, products, productsState]);

	return (
		<BackofficeProductContext.Provider value={value}>{children}</BackofficeProductContext.Provider>
	);
};

export function useBackofficeProducts() {
	return useContext(BackofficeProductContext);
}
