export enum Endpoints {

	LOGIN = '/user/authenticate',
	FORGOT_PASSWORD = '/forgot-password',
	USER_PRODUCTS = '/user-products/my-user-products-full',
	USER_PRODUCT_TAGS = '/user-product-tags',
	ENERGY_CONSUMPTION = '/energy-consumption',
	TARIFF_DATASET = 'user-product/{0}/tariffs',
	ALERTS = '/messages',
	ENERGY_CONSUMPTION_PERIOD_YEAR = '/energy-consumption/annual',
	ENERGY_CONSUMPTION_PERIOD_MONTH = '/energy-consumption/monthly',
	ENERGY_CONSUMPTION_PERIOD_WEEK = '/energy-consumption/weekly',
	ORDER = '/Orders',
	WALLBOX_CHARGES = '/boards/{0}/charges?page={4}&initiator={1}&begin={2}&end={3}&size={5}',
	COORPORATE = '/CooperateRequests/Create',
	CATEGORIES = '/ProductCategories?IsVisible={0}',
	CATEGORY_BY_ID = '/ProductCategories/{0}',
	WALLBOX_EXCEL_EXPORT = '/reports/excel/charges/by-wallbox',

	// New endpoint added here
	USER_PRODUCTS_USERS = '/user-products/{0}/users',
	CHARGES_EVENTS_BY_ID = '/charges/{0}/events?page={1}&size={2}',

	// App languages
	LANGUAGES = '/Languages',

	// STORE/BACKOFFICE
	STORE_AUTH = '/Auth',
	STORE_REFRESH_TOKEN = '/Auth/RefreshToken',
	STORE_CATEGORIES_ATTACHEMENT = '/ProductCategories/Attachment',
	STORE_CATEGORY = '/ProductCategories',
	STORE_CATEGORY_BY_ID = '/ProductCategories/{0}',
	JOIN_COMMUNITY = '/CommunityRequests',
	STORE_PRODUCTS = '/Products',
	STORE_PRODUCT_BY_ID = '/Products/{0}',
	STORE_PRODUCT_ATTACHMENT = '/Products/Attachment',
	STORE_ORDERS = '/Orders',
	STORE_ORDERS_NOTIFICATION = '/Orders/Notification',
	STORE_ORDER_STATUS = '/Orders/{0}/Status?status={1}',
	STORE_COMMUNITY_REQUESTS = '/CommunityRequests',
	STORE_QUESTION = '/Questions',
	STORE_QUESTION_BY_ID = '/Questions/{0}',
	STORE_QUESTION_ANSWERS = '/Questions/{0}/Answers',
	STORE_QUESTION_ANSWER_BY_ID = '/Questions/{0}/Answers/{1}',
	STORE_ADD_ANSWER_RULE = '/Questions/{0}/Answers/{1}/Rule',
	STORE_ANSWER_RULE_BY_ID = '/Questions/{0}/Answers/{1}/Rule/{2}',
	FIND_YOUR_IOKEE = '/FindMyIooke'
}
