/* eslint-disable no-alert */
import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useFormTabs } from '../providers/tab-provider';
import { useTranslation } from 'react-i18next';
import useLanguage from 'common/presentation/providers/language.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

interface Props {
	isEdit?: boolean
}

const FormTabHeader: React.FC<Props> = (props) => {
	const { isEdit } = props;
	const {
		tabs, addTab, activeTab, setActiveTab, unsavedChanges, setUnsavedChanges,
	} = useFormTabs();
	const { t } = useTranslation('translations');
	const { languages } = useLanguage();

	useEffectCustom(() => {
		if (isEdit && languages.length > 0 && tabs.length < languages.length) {
			addTab(t(languages[1].id), false);
		}
		setUnsavedChanges(false);
	}, [isEdit, languages]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	return (
		<Tabs
			value={activeTab}
			onChange={(event: React.SyntheticEvent, newValue: number) => {
				if (unsavedChanges) {
					if (window.confirm(t('unsavedChangesLostMessage'))) {
						setUnsavedChanges(false);
						handleTabChange(event, newValue);
					}
				} else {
					handleTabChange(event, newValue);
				}
			}}
			sx={{ mt: 2 }}
		>
			{tabs.map((tab) => (
				<Tab key={tab.id} label={tab.name} />
			))}
		</Tabs>
	);
};

export default FormTabHeader;
