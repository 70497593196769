import { Box, Card, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import Space from 'common/presentation/components/space/Space';
import { LoadingButton } from '@mui/lab';
import { useBackofficeQuestions } from '../../../providers/question.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { ILanguage } from 'common/domain/entities/languages';

interface Props {
	language: ILanguage
	isTranslationTab?: boolean
}

const BackofficeAddEditQuestionsPage: React.FC<Props> = (props) => {
	const { language, isTranslationTab } = props;
	const { questionId } = useParams();
	const { t } = useTranslation('translations');
	const {
		formValues,
		questionState,
		submit,
		questionFormErrors,
		getQuestionById,
		setFormValues,
		handleQuestionChange,
	} = useBackofficeQuestions();

	useEffectCustom(() => {
		if (questionId && language?.id) {
			getQuestionById(questionId, language.id);
		} else {
			setFormValues({
				description: '',
				stepOrder: 0,
			});
		}
	}, [questionId, language]);

	const handleSubmit = () => {
		submit(language.id, questionId);
	};

	return (
		<Box>
			<Card
				sx={{
					boxShadow: 'none',
					p: 4,
					mt: 4,
				}}
			>
				<Grid container spacing={2}>
					<Grid item md={5} xs={12}>
						<BackofficeTextField
							name="description"
							value={formValues.description}
							error={!!questionFormErrors.description}
							helperText={questionFormErrors.description}
							disabled={questionState.loading}
							onChange={handleQuestionChange}
							label={t('description')}
							placeholder={t('description')}
						/>
						{!isTranslationTab && (
							<>
								<Space height={2} />
								<BackofficeTextField
									name="stepOrder"
									type="number"
									value={formValues.stepOrder}
									error={!!questionFormErrors.stepOrder}
									helperText={questionFormErrors.stepOrder}
									disabled={questionState.loading}
									onChange={handleQuestionChange}
									label={t('step_order')}
									placeholder={t('step_order')}
								/>
							</>
						)}
						<Space height={2} />
						<LoadingButton type="submit" onClick={handleSubmit} loading={questionState.loading} variant="contained">
							{t('save')}
						</LoadingButton>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
};

export default BackofficeAddEditQuestionsPage;
