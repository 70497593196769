/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-catch */
import {
	storeDelete, storeGet, storePatch, storePost,
} from 'common/data/remote/store-requests';
import { Endpoints } from 'assets/Endpoints';
import { IAttachmentResp } from 'features/backoffice/common/domain/entities/attachment';
import { ApiResponse } from 'common/data/entities/api-response';
import { ErrorResponseBase } from 'common/domain/entities/error-response';
import {
	AttachmentProduct,
	BackofficeProductRequest,
	InstallationStep,
	ProductBackoffice,
	Specification,
} from '../entities/backoffice-product';
import { normalizeCurrency } from 'utils/price.utils';

export class ProductBackofficeApiImpl {
	async addBackofficeProduct(params: BackofficeProductRequest, language: string): Promise<ApiResponse<ProductBackoffice>> {
		const reqData = { ...params, price: normalizeCurrency(params.price).toString() };
		const mSteps = [...reqData.installationSteps];
		mSteps.forEach((el, index) => {
			el.stepOrder = index + 1;
		});
		reqData.installationSteps = mSteps;

		try {
			const req = await storePost({
				url: Endpoints.STORE_PRODUCTS,
				data: { ...reqData },
				headers: {
					'Iokee-Service-Language': language,
				},
			});
			const data = req.data as ApiResponse<ProductBackoffice>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async updateBackofficeProduct(
		params: BackofficeProductRequest,
		id: string,
		language: string,
	): Promise<ApiResponse<ProductBackoffice>> {
		const reqData = { ...params, price: normalizeCurrency(params.price).toString() };
		const mSteps = [...reqData.installationSteps];
		mSteps.forEach((el, index) => {
			el.stepOrder = index + 1;
		});
		reqData.installationSteps = mSteps;

		try {
			const req = await storePatch({
				url: Endpoints.STORE_PRODUCT_BY_ID.format(id),
				data: { ...reqData },
				headers: {
					'Iokee-Service-Language': language,
				},
			});
			const { data } = req;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async uploadMultipleProductAttachments(
		params: AttachmentProduct | InstallationStep | Specification,
	): Promise<string> {
		const data = new FormData();
		data.append('file', params.attachmentId);
		const res = await storePost({
			url: Endpoints.STORE_PRODUCT_ATTACHMENT,
			data,
		});
		return res?.data?.data?.id as string;
	}

	async uploadProductAttachments(params: File): Promise<IAttachmentResp> {
		const data = new FormData();
		data.append('file', params);
		const res = await storePost({
			url: Endpoints.STORE_PRODUCT_ATTACHMENT,
			data,
		});
		return res?.data?.data as IAttachmentResp;
	}

	async getBackofficeProducts(): Promise<ProductBackoffice[]> {
		try {
			const res = await storeGet({
				url: Endpoints.STORE_PRODUCTS,
				headers: {
					'Iokee-Service-Language': 'pt',
				},
			});
			const data = res.data as ApiResponse<ProductBackoffice[]>;
			return data.data;
		} catch (error) {
			throw error;
		}
	}

	async deleteProductById(id: string): Promise<ApiResponse<ProductBackoffice>> {
		try {
			const res = await storeDelete({
				url: Endpoints.STORE_PRODUCT_BY_ID.format(id),
			});
			const data = res.data as ApiResponse<ProductBackoffice>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getProductById(id: string, language: string): Promise<ProductBackoffice> {
		try {
			const res = await storeGet({
				url: Endpoints.STORE_PRODUCT_BY_ID.format(id),
				headers: {
					'Iokee-Service-Language': language,
				},
			});
			const data = res.data as ApiResponse<ProductBackoffice>;
			return data.data;
		} catch (error) {
			throw error;
		}
	}
}
