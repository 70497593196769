/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { IProductCategory, IProductCategoryErrors, IProductCategoryReq } from '../product-categories/entities/product-categories';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useFormTabs } from '../../common/presentation/providers/tab-provider';
import { CategoryApiImpl } from '../product-categories/data/categories-api-impl';

interface FetchState {
	loading: boolean;
	error: string;
}

type Props = {
	formValues: IProductCategoryReq
	formErrors: IProductCategoryErrors
	onChange: (key: string, value: string | boolean | File) => void
	submit: (language: string, id?: string) => void
	getCategories: () => void
	categories: IProductCategory[]
	loading: boolean
	getCategoryById: (id: string, language: string) => void
	deleteCategoryById: (id: string) => void
	setFormValues: React.Dispatch<React.SetStateAction<IProductCategoryReq>>
	categoriesFetchState: FetchState
}

export const ProductCategoriesContext = createContext({} as Props);

export const ProductCategoriesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { addDialog, addToast } = useFeedback();
	const { setIsAddTabButtonDisabled, setUnsavedChanges, setActiveTab } = useFormTabs();
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([] as IProductCategory[]);
	const [categoriesFetchState, setCategoriesFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [formValues, setFormValues] = useState<IProductCategoryReq>({
		name: '',
		file: '',
		subtitle: '',
		description: '',
		isVisible: true,
	});
	const [formErrors, setFormErrors] = useState({
		name: '',
		file: '',
		subtitle: '',
		description: '',
		isVisible: false,
	});
	const [attachmentId, setAttachmentId] = useState(0);
	const categoryApi = new CategoryApiImpl();

	const getCategories = async () => {
		setCategoriesFetchState({ error: '', loading: true });
		try {
			const categoriesResp = await categoryApi.getCategories();
			setCategories(categoriesResp);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setCategoriesFetchState({ error: '', loading: false });
		}
	};

	const deleteCategoryById = async (id: string) => {
		try {
			const deleteCategoriesResp = await categoryApi.deleteCategoryById(id);
			addToast({
				error: false,
				message: deleteCategoriesResp.message,
			});
			getCategories();
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const getCategoryById = async (id: string, language: string) => {
		try {
			const categoriesResp = await categoryApi.getCategoryById(id, language);
			setFormValues({
				name: categoriesResp.name,
				isVisible: categoriesResp.isVisible,
				subtitle: categoriesResp.subtitle || '',
				description: categoriesResp.description || '',
				file: categoriesResp.attachment.url,
			});
			setAttachmentId(categoriesResp.attachment.id);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const onChange = (key: string, value: string | boolean | File) => {
		setFormValues({ ...formValues, [key]: value });
		setFormErrors({ ...formErrors, [key]: '' });
		setIsAddTabButtonDisabled(true);
		setUnsavedChanges(true);
	};

	const submit = async (language: string, id?: string) => {
		const errors = {
			name: '',
			file: '',
			subtitle: '',
			description: '',
		};
		if (!formValues.name) {
			errors.name = t('required');
		}
		if (!formValues.subtitle) {
			errors.subtitle = t('required');
		}
		if (!formValues.description) {
			errors.description = t('required');
		}
		if (!formValues.file) {
			errors.file = t('required');
		}
		setFormErrors({ ...formErrors, ...errors });
		if (errors.name || errors.file || errors.subtitle || errors.description) {
			return;
		}
		setLoading(true);
		if (id) {
			try {
				const updateCategoryRes = await categoryApi.updateCategoryById(id, attachmentId, language, formValues);
				addToast({
					error: false,
					message: updateCategoryRes.message,
				});
				setIsAddTabButtonDisabled(false);
				setUnsavedChanges(false);
				setActiveTab(0);
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
				setLoading(false);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				const addCategoryRes = await categoryApi.addCategory(formValues, language);
				addToast({
					error: false,
					message: addCategoryRes.message,
				});
				navigate(AppLinks.BACKOFFICE_EDIT_PRODUCT_CATEGORIES.formatMap({ categoryId: addCategoryRes.data.id }));
				setUnsavedChanges(false);
				setActiveTab(0);
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const value = useMemo(() => ({
		formValues,
		formErrors,
		onChange,
		submit,
		loading,
		categories,
		getCategories,
		getCategoryById,
		deleteCategoryById,
		setFormValues,
		categoriesFetchState,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		formValues,
		formErrors,
		categories,
		loading,
		categoriesFetchState,
	]);

	return (
		<ProductCategoriesContext.Provider
			value={value}
		>
			{children}
		</ProductCategoriesContext.Provider>
	);
};

export function useCategories() {
	return useContext(ProductCategoriesContext);
}
