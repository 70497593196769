import { BodyText } from 'common/presentation/components';
import { Card, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Space from 'common/presentation/components/space/Space';
import { Form, Formik, FormikErrors } from 'formik';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import { LoadingButton } from '@mui/lab';
import { IAnswerReq } from '../../../entities/answer.model';
import { useBackofficeQuestions } from 'features/backoffice/features/providers/question.provider';
import { ILanguage } from 'common/domain/entities/languages';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';

interface Props {
	questionId?: string;
	answerId: number | null;
	language: ILanguage;
}

const AnswerForm: React.FC<Props> = (props) => {
	const { questionId, answerId, language } = props;
	const { t } = useTranslation('translations');
	const { answer, submitAnswer, getAnswerById } = useBackofficeQuestions();
	const { setUnsavedChanges } = useFormTabs();

	useEffectCustom(() => {
		if (questionId && answerId && language?.id) {
			getAnswerById(questionId, answerId, language.id);
		}
	}, [answerId, questionId, language]);

	return (
		<Card
			sx={{
				boxShadow: 'none',
				p: 4,
				mt: 4,
			}}
		>
			<BodyText>
				{t('add_answers')}
			</BodyText>
			<Space height={4} />
			<Formik
				initialValues={answer}
				onSubmit={(values, helpers) => submitAnswer(values, helpers, language.id, answerId ?? undefined, questionId)}
				validate={(values) => {
					const errors = {} as FormikErrors<IAnswerReq>;
					if (!values.text) {
						errors.text = t('required');
					}
					return errors;
				}}
				enableReinitialize
			>
				{({
					errors,
					values,
					handleChange,
					isSubmitting,
				}) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item md={5} xs={12}>
								<BackofficeTextField
									name="text"
									value={values.text}
									error={!!errors.text}
									helperText={errors.text}
									disabled={isSubmitting}
									onChange={(e) => {
										handleChange(e);
										setUnsavedChanges(true);
									}}
									label={t('name')}
									placeholder={t('name')}
								/>
								<Space height={2} />
								<LoadingButton type="submit" loading={isSubmitting} variant="contained">
									{t('save')}
								</LoadingButton>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Card>
	);
};

export default AnswerForm;
