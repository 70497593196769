import { placeholderImage } from 'assets/images';
import { Box } from '@mui/system';
import React from 'react';

interface Props {
	imageToPreview?: string
	disabled: boolean
	onImageChange?: (image: File) => void
	id: string
}

const SpecImage: React.FC<Props> = (props) => {
	const {
		imageToPreview,
		disabled,
		onImageChange,
		id,
	} = props;
	const imageRef = React.useRef<HTMLInputElement | null>(null);
	const getImageSource = (src?: string) => {
		if (typeof src === 'string') {
			if (!src) {
				return placeholderImage;
			}
			return src;
		}
		return placeholderImage;
	};
	return (
		<Box onClick={() => imageRef.current?.click()}>
			<input
				type="file"
				name="image"
				id={id}
				accept="image/png, image/jpeg"
				ref={imageRef}
				disabled={disabled}
				style={{ display: 'none' }}
				onChange={(e) => {
					const ev = e.currentTarget.files;
					if (ev) {
						if (ev.length === 0) {
							return;
						}
						onImageChange?.call(0, ev[0]);
					}
				}}
			/>
			<img src={getImageSource(imageToPreview)} alt="" width="50px" height="50px" />
		</Box>
	);
};

export default SpecImage;
