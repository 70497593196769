import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
import FormTabHeader from 'features/backoffice/common/presentation/form-tab-header/FormTabHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackofficeAddEditProductPage from './BackofficeAddEditProductPage';
import BackofficeAddEditProductTranslation from './BackofficeAddEditProductTranslation';
import { BodyText } from 'common/presentation/components';
import { useParams } from 'react-router-dom';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import useLanguage from 'common/presentation/providers/language.provider';

const BackofficeAddEditProduct = () => {
	const { t } = useTranslation('translations');
	const { productId } = useParams();
	const { activeTab, resetTabs } = useFormTabs();
	const { languages } = useLanguage();

	useEffectCustom(() => {
		if (!productId) {
			resetTabs();
		}
	}, [productId]);

	return (
		<main>
			<BodyText>
				{productId
					? t('edit_product')
					: t('add_product')}
			</BodyText>
			<FormTabHeader isEdit={!!productId} />
			{activeTab === 0 && <BackofficeAddEditProductPage language={languages.find((el) => el.isDefault) || languages[0]} />}
			{activeTab === 1 && <BackofficeAddEditProductTranslation language={languages.find((el) => !el.isDefault) || languages[1]} />}
		</main>
	);
};

export default BackofficeAddEditProduct;
