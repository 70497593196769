import { BodyText } from 'common/presentation/components';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
import FormTabHeader from 'features/backoffice/common/presentation/form-tab-header/FormTabHeader';
import BackofficeAddEditProductCategoriesPage from './BackofficeAddEditProductCategoriesPage';
import BackofficeAddEditProductCategoriesTranslatedForm from './BackofficeAddEditProductCategoriesTranslatedForm';
import useLanguage from 'common/presentation/providers/language.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
// import { useCategories } from '../../../providers/product-categories.provider';

const BackofficeAddEditProductCategories = () => {
	const { t } = useTranslation('translations');
	const { categoryId } = useParams();
	const { languages } = useLanguage();
	const { activeTab, resetTabs, setActiveTab } = useFormTabs();

	useEffectCustom(() => {
		if (!categoryId) {
			resetTabs();
		}
		setActiveTab(0);
	}, [categoryId]);

	return (
		<Box>
			<BodyText>
				{categoryId
					? t('edit_product_category')
					: t('add_product_category')}
			</BodyText>
			<FormTabHeader isEdit={!!categoryId} />
			{activeTab === 0 && <BackofficeAddEditProductCategoriesPage language={languages.find((el) => el.isDefault) || languages[0]} />}
			{activeTab === 1 && <BackofficeAddEditProductCategoriesTranslatedForm language={languages.find((el) => !el.isDefault) || languages[1]} />}
		</Box>
	);
};

export default BackofficeAddEditProductCategories;
